import UserContext from "../stores/user-context";
import { useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";
import { Breakpoint } from "../utils/media-query";
import dynamic from "next/dynamic";
import Link from "next/link";

const LoadingText = () => <p>Loading...</p>;
const LoginForm = dynamic(() => import("../components/loginpage/login-form"), {
  loading: LoadingText,
});
const LoginPage = () => {
  const userCtx = useContext(UserContext);
  const router = useRouter();
  const [isDesktop] = Breakpoint();

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // useEffect(() => {
  //   if (userCtx.user) {
  //     window.location.reload();
  //   }
  // }, [userCtx.user, router]);

  if (isMounted) {
    if (!userCtx.user) {
      return (
        <>
          <LoginForm />
        </>
      );
    }
    if (userCtx.user) {
      return (
        <>
          <div className={isDesktop ? "container" : "container-mobile"}>
            <div className="center my-5">
              <div>
                <div
                  style={{
                    fontSize: "1.3rem",
                    textAlign: "center",
                    marginBottom: "1rem",
                  }}
                >
                  You have successfully logged in!
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link href="/all-jewelries">
                    <span style={{ marginRight: ".3rem", fontWeight: "bold" }}>
                      Click here
                    </span>
                    <span>
                      to explore our exclusive jewelry collection and indulge in
                      elegance. Happy shopping!
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
};

export default LoginPage;
